<template>
<div style="background-color: #ffffff;">
  <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}">
    <div class="titleBar_left">
      <img  @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="../assets/logo.png">
      <div class="titleBar_left_txt">
        用户管理中心
      </div>
    </div>
    <div class="titleBar_right" @click="replace('/')">
      返回首页
    </div>
  </div>
</div>
</template>
<script>
import oucy from '../util/oucyUtil'
export default {
  name: "acchead",
  data(){
    return{
      pageWidth: oucy.pageWidth
    }
  },
  methods:{
    replace(path) {
      this.$router.replace(path)
    },
  }
}
</script>

<style>
.titleBar {
  height: 100px;
  /*background-color: #0366C3;*/
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.titleBar_left {
  /*background-color: #42b983;*/
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 600px;
}

.titleBar_left_txt {
  /*background-color: #409EFF;*/
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  font-size: 25px;
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px #eeeeee solid;
  color: gray;
}

.titleBar_right {
  font-size: 22px;
  color: gray;
}

.titleBar_right:hover {
  cursor: pointer;
  color: #000000;
}
</style>