<template>
    <div>
        <acchead></acchead>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="item.path">{{item.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1');">帮助手册</el-button>
        </div>

        <div class="pageCenter"  :style="{width: pageWidth+'px'}">
            <el-container>
                <el-aside width="120px" style="margin-top: 10px">
                    <el-menu class="el-menu-vertical-demo" router>
                        <el-menu-item index="/acc">
                            <img src="../../assets/icon/acc_nav1.png" style="width: 15px;padding-right:5px">
                            <span slot="title">个人中心</span>
                        </el-menu-item>
                        <el-menu-item index="/askBuyManagement">
                            <img src="../../assets/icon/acc_nav2.png" style="width: 15px;padding-right:5px">
                            <span slot="title">求购管理</span>
                        </el-menu-item>
                        <el-menu-item  index="/acc/OMS">
                            <img src="../../assets/icon/acc_nav1.png" style="width: 15px;padding-right:5px">
                            <span slot="title">订单管理</span>
                        </el-menu-item>
                        <el-menu-item  index="/acc/liveList" >
                            <img src="../../assets/icon/acc_nav1.png" style="width: 15px;padding-right:5px">
                            <span slot="title">直播管理</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/StockCar">
                        <img src="../../assets/icon/acc_nav4.png" style="width: 15px;padding-right:5px">
                        <span slot="title">进货清单</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/distribution/distribution">
                            <img src="../../assets/icon/acc_nav5.png" style="width: 15px;padding-right:5px">
                            <span slot="title" disabled>分销管理</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/MemberCard">
                            <img src="../../assets/icon/acc_nav5.png" style="width: 15px;padding-right:5px">
                            <span slot="title" disabled>我的会员卡</span>
                        </el-menu-item>

                        <el-menu-item>
                            <img src="../../assets/icon/acc_nav7.png" style="width: 15px;padding-right:5px">
                            <span slot="title" disabled>采购偏好</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/editpassword">
                            <img src="../../assets/icon/acc_nav8.png" style="width: 15px;padding-right:5px">
                            <span slot="title">修改密码</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/findpassword">
                            <img src="../../assets/icon/acc_nav8.png" style="width: 15px;padding-right:5px">
                            <span slot="title">找回密码</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/collection">
                          <img src="../../assets/icon/acc_nav6.png" style="width: 15px;padding-right:5px">
                          <span slot="title">我的收藏</span>
                        </el-menu-item>
                        <el-menu-item index="/acc/comment">
                          <img src="../../assets/icon/acc_nav6.png" style="width: 15px;padding-right:5px">
                          <span slot="title">我的评论</span>
                        </el-menu-item>
                        <el-menu-item v-if="0">
                            <img src="../../assets/icon/acc_nav9.png" style="width: 15px;padding-right:5px">
                            <span slot="title">客服中心</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                    <div style="padding: 10px 0 50px 10px;width: 100%">
                        <router-view/>
                    </div>
                </el-container>
            </el-container>
        </div>
    </div>

</template>

<script>
    import oucy from '../../util/oucyUtil'
    import acchead from '../../components/acchead'
    import {userAuth} from '@/service/index.js'
    import {localSet, localGet, localDel} from "@/store/store"
    import Navbottom from "@/components/Navbottom";
    export default {
      components: {
        Navbottom,
        acchead,
      },
        name: 'Acc',
        data() {
            return {
                pageWidth: oucy.pageWidth,
                levelList: []
            }
        },
        watch: {
            $route: {
                handler(to,from) {
                  this.getBreadcrumb()
                },
                deep:true,
                immediate: true
            }
        },
        mounted(){

             let enterprise=localGet('enterprise')
             if(enterprise && enterprise.id){
                this.getStatus(enterprise)
             }else{
                 // 查询客户端用户的所有企业
                 userAuth.getEnterpriseByUser({}).then(res=>{
                    console.log(res)
                    if(res && res.length){
                        let enterprise=res[0]
                        for(let v of res){
                            if(v.isdefault){
                                enterprise=v
                            }
                        }
                        this.getStatus(enterprise)
                    }
                 })
             }
             //监听登录事件
             this.eventBus/*.$off(this.$oucy.msgKeys.LOGIN_SUCCESS)*/.$on(this.$oucy.msgKeys.LOGIN_SUCCESS,(res)=>{
                 console.log('登录成功');
                  location.reload();
             });

        },
        methods: {
            getStatus(enterprise){
                 localSet('enterprise',enterprise)
                oucy.queryEnterApplyStatus({enterpriseId:enterprise.id}).then(res=>{
                    this.statu=res.status
                    this.$store.commit('setStatu',this.statu)
                })

                 oucy.queryEnterpriseIdentifyStatus({enterpriseId:enterprise.id}).then(res=>{
                     this.status=res
                     this.$store.commit('setStatus',this.status)
                 })
            },
            go(path) {
                this.$router.push(to)
            },
            replace(path) {
                this.$router.replace(path)
            },
            getBreadcrumb() {
                //$route.matched是一个数组 包含当前路由所有嵌套路径的路由记录
                let matched = this.$route.matched.filter(item => item.name)
                const first = matched[0];
                if (first && first.name !== '首页') {
                    matched = [{ path: '/', meta: { title: '首页' }}].concat(matched)
                }
                this.levelList = matched
            }
        }
    }
</script>

<style scoped>

    .titleBar {
        height: 100px;
        /*background-color: #0366C3;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .titleBar_left {
        /*background-color: #42b983;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .titleBar_left_txt {
        /*background-color: #409EFF;*/
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 50px;
        font-size: 25px;
        padding-left: 20px;
        margin-left: 20px;
        border-left: 1px #eeeeee solid;
        color: gray;
    }

    .titleBar_right {
        font-size: 25px;
        color: gray;
    }

    .titleBar_right:hover {
        cursor: pointer;
        color: #000000;
    }

    .breadcrumbBar {
        padding: 10px auto;
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .el-menu-vertical-demo {
        text-align: left;
        min-height: 700px;
        border-radius: 4px;
    }
    .el-menu .el-menu-item{
      border-right: 0 !important;
      color: #888888;
    }
</style>
